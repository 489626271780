import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);

    // Toggle between showing and hiding the mobile menu
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    VELOCIGRAFT
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    {/* Update these classes to match your icon library classes */}
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    {/* These are your existing links, they will be hidden on mobile */}
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/our-people' className='nav-links' onClick={closeMobileMenu}>Our People</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/products' className='nav-links' onClick={closeMobileMenu}>Products</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/awards' className='nav-links' onClick={closeMobileMenu}>Awards</Link>
                    </li>
                </ul>
                {click && (
                    <div className="mobile-menu active">
                        {/* These are your mobile links, they will show in a dropdown on mobile */}
                        <Link to='/' className='mobile-menu-item' onClick={closeMobileMenu}>Home</Link>
                        <Link to='/our-people' className='mobile-menu-item' onClick={closeMobileMenu}>Our People</Link>
                        <Link to='/products' className='mobile-menu-item' onClick={closeMobileMenu}>Products</Link>
                        <Link to='/awards' className='mobile-menu-item' onClick={closeMobileMenu}>Awards</Link>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
