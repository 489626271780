import React from 'react';
import './footer.css'; // import the CSS file
import { Link } from 'react-router-dom';

const Footer = () => {
    const handleLinkClick = (event, url) => {
        event.preventDefault();
        window.open(url, '_blank');
    };
    const closeMobileMenu = () => {
        console.log('Link clicked, closing mobile menu...')
    };

    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-section about">
                    <h1 className="logo-text"><span>Velocigraft</span></h1>
                    <p>Velocigraft is a revolutionary skin meshing device intended for limited-resource healthcare settings in Sub-Saharan Africa.</p>
                </div>

                <div className="footer-section links">
                    <h2>Quick Links</h2>
                    <br/>
                    <ul>
                        <li>
                            <Link to='/our-people' className='footer-nav-links' onClick={closeMobileMenu}>
                                Our People
                            </Link>
                        </li>
                        <li>
                            <Link to='/products' className='footer-nav-links' onClick={closeMobileMenu}>
                                Products
                            </Link>
                        </li>
                        <li>
                            <Link to='/awards' className='footer-nav-links' onClick={closeMobileMenu}>
                                Awards
                            </Link>
                        </li>
                        <li>
                            <Link to='/home' className='footer-nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="footer-section contact-form">
                    <h2>Contact us</h2>
                    <br/>
                    <p>Email: velocigraft@gmail.com</p>
                    <p>Phone: 724-766-9724</p>
                    <div className="socials">
                        <a href="/" onClick={(event) => handleLinkClick(event, 'https://www.linkedin.com/company/velocigraft/')}>Linkedin</a>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Velocigraft. All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;