import React from 'react';
import '../App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/footer';
import logo from '../assets/VelocigraftLogoTransparent.png';
import dalhartHeadshot from '../assets/DalhartHeadshot.jpg';
import aarushiHeadshot from '../assets/AarushiHeadshot.jpg';
import ashleyHeadshot from '../assets/AshleyHeadshot.jpg';
import ojasHeadshot from '../assets/OjasHeadshot.jpg';
import adityaHeadshot from '../assets/AdityaHeadshot.jpg';
import christineHeadshot from '../assets/ChristineHeadshot.jpg';
import joanneHeadshot from '../assets/JoanneHeadshot.jpg';
import pareeHeadshot from '../assets/PareeHeadshot.jpg';
import thoyaHeadshot from '../assets/ThoyaHeadshot.jpg';



const TeamMember = ({ member, isLast }) => {
  const useStyles = makeStyles((theme) => ({
    large: {
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
    small: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    logo: {
      width: '100%',
      height: 0,
      paddingBottom: '100%',
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundPosition: 'top-center',
    }
  }));
  const classes = useStyles();

  const memberComponent = (
    <Grid item xs={12} sm={6} md={4} lg={2} align="center">
      <a href={member.website} target="_blank" rel="noopener noreferrer">
        <div 
          className={classes.logo} 
          style={{ backgroundImage: `url(${member.image})`, backgroundPosition: member.backgroundPosition || 'center'}}
        />
      </a>
      <h2>{member.name}</h2>
      <p>{member.bio}</p>
    </Grid>
  );

  return isLast ? (
    <Grid container justifyContent="center">
      {memberComponent}
    </Grid>
  ) : (
    memberComponent
  );
};

const OurPeople = () => {
  const teamMembers = [
    
    {
      name: 'Team Leader: Ojas Chahal',
      bio: 'BME: Cellular and Tissue Engineering 2025',
      image: ojasHeadshot,
      website: 'https://www.linkedin.com/in/ojaschahal/'
    },
    {
        name: 'Dalhart Dobbs',
        bio: 'BME: Cellular and Tissue Engineering 2025',
        image: dalhartHeadshot,
        website: 'https://www.linkedin.com/in/dalhart-dobbs/',
        backgroundPosition: 'center top'
    },
    {
        name: 'Aarushi Pant',
        bio: 'Behavioral Biology & BME: Immunoengineering 2026',
        image: aarushiHeadshot,
        website: 'https://www.linkedin.com/in/aarushi-pant-6885001b3/',
        backgroundPosition: 'center top'
    },
    {
        name: 'Ashley Cluff',
        bio: 'BME: Neuroengineering 2025',
        image: ashleyHeadshot,
        website: 'https://www.linkedin.com/in/ashleycluff/',
        backgroundPosition: 'bottom-center'
    },
    {
        name: 'Joanne Wang',
        bio: 'BME: Biomedical Data Science 2024',
        image: joanneHeadshot,
        website: 'https://www.linkedin.com/in/jaeyoon-wang/',
        backgroundPosition: '50% 20%',
    },
    {
      name: 'Christine Wang',
      bio: 'BME: Cellular and Tissue Engineering 2024',
      image: christineHeadshot,
      website: 'https://www.linkedin.com/in/christinewang1001/'
    },
    {
        name: 'Aditya Srinivasan',
        bio: 'BME: 2027',
        image: adityaHeadshot,
        website: 'https://www.linkedin.com/in/aditya-shrinivasan-26ba73264/'
    },
    {
        name: 'Thoya Raman',
        bio: 'BME: 2027',
        image: thoyaHeadshot,
        website: 'https://www.linkedin.com/in/thoya-raman/'

    },
    {
        name: 'Paree Sharma',
        bio: 'BME: 2027',
        image: pareeHeadshot,
        website: 'https://www.linkedin.com/in/paree-sharma-838906300/'
    },
  ];

  return (
    <div className="team-container">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px' }}>
        <h1>Meet The </h1>
        <img src={logo} alt="Logo" style={{width: '20%', minWidth: '100px', maxWidth: '300px', height: 'auto', margin: '0 10px'}} />
        <h1> Team</h1>
      </div>
      <Grid container justifyContent="center" spacing={4}>
        {teamMembers.map((member, index) => (
          <TeamMember key={index} member={member} />
        ))}
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '50px' }}>
      </div>
      <Footer />
    </div>
  );
};
export default OurPeople;